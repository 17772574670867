
@import "stylesheets/globals/variables";
@import "stylesheets/scout/variables";

.missing-text {
  color: $pill-gray;
}

.a-form label {
  font-weight: 400;
}

:deep {
  .open > .dropdown-menu {
    > li {
      > a {
        strong, b {
          color: $k-purple
        }
      }
    }
  }
}

:deep {
  .check-option {
    input:checked + .check.c-box {
      border-color: $purple-5;
      background-color: $purple-5;
    }
  }
}

