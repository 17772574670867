
@import "stylesheets/globals/variables";

.fade-scroll-container {
  .fade-scrollable {
    max-height: 170px;
  }
}

.highlighted {
  :deep {
    strong {
      color: $k-purple;
    }
  }
}

:deep(.check-option) {
  font-weight: 400;

  input:checked + .check.c-box {
    border-color: $purple-5;
    background-color: $purple-5;
  }
}
